<template>
  <v-main>
    <v-container class="d-flex justify-center"><v-card class="pa-16" max-width="1800"><row class="d-flex justify-center"><img height="100" contain class="ma-1" src="/smartsizetext.png"></img></row><v-row class="d-flex justify-center mb-16 mt-5 bold-text"><v-icon class="mr-4" >mdi-play-circle-outline</v-icon  >Create your first size chart in less than 5 minutes. Fast and simple. </v-row>
    
      <div class="d-flex justify-center">

        <v-card
        
        color="#F1F3F0"
    class="d-flex flex-column justify-space-between ma-5 pr-10"
    max-width="344"
    variant="outlined"
  >
    <v-card-item>
      <div>
        <div class="text-overline mt-10 mb-1 ml-10 red-text" style="font-weight: bold;"> 
          STEP 1
        </div>
        <div class="text-h6 mb-1 ml-10">
          Embed SmartSize
        </div>
        <div class="text-caption ml-10">Embed the app in your Shopify theme. Click below to start embedding. <strong><span style="color: red;">Make sure to click SAVE after you are done.</span></strong>
</div>
      </div>
    </v-card-item>

    <v-card-actions>
      <v-btn
                class="mt-5 mb-10 ml-8"
                depressed
                dark
                :href="url"
                target="_blank"
                >ACTIVATE
              </v-btn>
    </v-card-actions>
  </v-card>

  <v-card
  color="#F1F3F0"
    class="d-flex flex-column justify-space-between ma-5 pr-10"
    max-width="344"
    variant="outlined"
  >
    <v-card-item>
      <div>
        <div class="text-overline mt-10 mb-1 ml-10 red-text" style="font-weight: bold;"> 
          STEP 2
        </div>
        <div class="text-h6 mb-1 ml-10">
Create new size chart        </div>
        <div class="text-caption ml-10">Create your size chart, link it to a product and publish it.</div>
      </div>
    </v-card-item>

    <v-card-actions>
     
              <v-btn class="mt-5 mb-10 ml-8" @click="goNew" targe3="_blank" depressed dark>
               NEW SIZE
                CHART</v-btn
              >
    </v-card-actions>
  </v-card>


 </div>

</v-card></v-container>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      dialog_vip: false,
      url: "",
      url_app_block: "",
      messages: [
        {
          from: "Configuration and Size Chart Creation",
          message:
            "Our team will configure SmartSize for you and create the first two size charts for your store so that you can focus on growing your sales",
          color: "cyan",
        },
        {
          from: "Unlimited Size Charts",
          message: `Create and publish unlimited size charts for your products`,
          color: "cyan",
        },
        {
          from: "Unrestricted displays",
          message:
            "No limitations into the amount in which the size charts are being displayed",
          color: "cyan",
        },
        {
          from: "Preferential support",
          message: "Get preferential support from our team",
          color: "cyan",
        },
      ],
    }
  },
  beforeMount(to, from, next) {
    this.$store.dispatch("resetInfiniteLoading");
  },
  methods: {
    goNew() {
      this.$router.push({ name: "Templates" });
    },
    async selectGrowthPlan() {
      let plan = {};
      await this.$http
        .get(process.env.VUE_APP_SERVER_URL + "/plan/setplan", {
          params: {
            shop: this.$store.getters.getShopProfile.shopname,
            id: 6, // 6 = Growth Plan 6.99
          },
        })
        .then((response) => (plan = response.data))
        .catch((error) => console.log(error));

      top.window.location = plan.redirect_url;
    },
  },
  mounted() {
    let shop = localStorage.getItem("shop");
    this.url = `https://${shop}/admin/themes/current/editor?context=apps&template=product&activateAppId=${process.env.VUE_APP_THEME_EXTENSION_UUID}/app-embed`;
    this.url_app_block = `https://${shop}/admin/themes/current/editor?context=products&template=product`;
  },
};
</script>
<style scoped>
.card-text {
  font-size: 16px;
  margin: 10px;
}
</style>
