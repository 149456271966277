<template>
  <!-- Icons -->
  <div class="background">
    <Welcome></Welcome>
    <SmartSizeFooter></SmartSizeFooter>
  </div>
  <!-- Icons -->
</template>

<script>
import Welcome from "@/components/Welcome/Welcome.vue";
import SmartSizeFooter from "@/components/GlobalComponents/SmartSizeFooter.vue";

export default {
  data() {
    return {};
  },
  components: {
    Welcome,
    SmartSizeFooter,
  },
};
</script>

<style>
.background {
  background-color: #f3f5f7;
  min-height: 100vh;
}
</style>